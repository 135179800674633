require('./bootstrap');
window.$ = require('jquery');

import { Modal } from 'bootstrap'

var galleryModal = null;

$(window).on( 'load', function(){				
	$.each( $('img[data-gallery]'), function( idx, img ){
		
		$(img).on( 'click', function(){			
			if( galleryModal == null ){
				$('body').append('\
				<div id="gallery_modal" class="modal" tabindex="-1">\
					<div class="modal-dialog modal-lg">\
						<div class="modal-content">\
							<div class="modal-body">\
								<img class="img-fluid" />\
							</div>\
						</div>\
					</div>\
				</div>\
				');

				galleryModal = new Modal( $('#gallery_modal') );
			}

			$('#gallery_modal img').attr('src', $(img).attr('data-gallery') );

			galleryModal.show();
		});
	});				
});	